import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table from 'reactstrap/lib/Table';

import { LinkWidgetTableHead } from './link-widget-table-head';
import { LinkWidgetTableBody } from './link-widget-table-body';

import './link-widget-table.scss';

/**
 * Renders link widget table.
 * @param {object} data
 * @param {node} children
 * @param {string} tableClassName
 * @param {string} headerClassName
 * @returns {ReactElement|null}
 */

export class LinkWidgetTable extends Component {
  static propTypes = {
    data: PropTypes.shape({}),
    children: PropTypes.node,
    tableClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    data: null,
    children: null,
    tableClassName: '',
    headerClassName: 'text-gray-darker',
    className: '',
  };

  state = {
    // First column of the table has position sticky
    // On mobile we add additional styles to it when user touches the table
    isTouched: false,
  };

  setTouched = () => this.setState({ isTouched: true });

  removeTouched = () => this.setState({ isTouched: false });

  render() {
    const { data, children, tableClassName, headerClassName, className } = this.props;
    const { isTouched } = this.state;

    if (!data || !(data.records && data.records.length)) return null;

    const { headers, records, caption, footnote } = data;

    return (
      <section
        className={classnames('link-widget-table link-widget', className)}
        data-tracking-parent="edm-link-widget-table"
      >
        <h2 className="heading-3 mb-1_5">{caption}</h2>
        <Table
          responsive
          borderless
          className={classnames('size-16 mb-0 text-gray-darker', tableClassName, { stuck: isTouched })}
          onTouchStart={this.setTouched}
          onTouchEnd={this.removeTouched}
        >
          <caption className="visually-hidden">{caption}</caption>
          <LinkWidgetTableHead headers={headers} className={headerClassName} />
          <LinkWidgetTableBody records={records} />
        </Table>
        {!!footnote && <p className="mt-1 mb-0 size-16">{footnote}</p>}
        {!!children && <p className="mt-1 mb-0 size-16">{children}</p>}
      </section>
    );
  }
}
